const ServerUrl = {
  BASE_URL: "https://sidraadmin.social/",
  WS_BASE_URL: "wss://ws.sidraadmin.social/",
  BASE_FILEUPLOAD_URL:"https://api-uat-user.sidrabazar.com/",
  BASE_SENTENCETRANS_URL:"https://translation.googleapis.com/",
  PUSH_NOTIFICATION_BASE:"https://5z7l1nh4-8000.inc1.devtunnels.ms/"
};

// BASE_URL: "https://api-dev.sidraadmin.social/",
// WS_BASE_URL: "wss://ws-dev.sidraadmin.social/",

export default ServerUrl;
