// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging , getToken } from "firebase/messaging";
import ApiConnector from "../api/apiConnector";
import ApiEndpoints from "../api/apiEndpoints";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAmM8-EDigtcu8vfxBlIkhmEkEXxwzB6qk",
  authDomain: "whatsappconsole-e7638.firebaseapp.com",
  projectId: "whatsappconsole-e7638",
  storageBucket: "whatsappconsole-e7638.appspot.com",
  messagingSenderId: "100949253926",
  appId: "1:100949253926:web:51895b2edb146e05902be7",
  measurementId: "G-N9LCWDPR6L"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);


export const generateToken = async () => {
    const permission = await Notification.requestPermission();
    console.log(permission)

    try{
      if (permission==="granted") {
        const token = await getToken(messaging,{
            vapidKey:"BHL9lTFpItovWAu9bkIZqra736gWERi0UB8F65f4yqBAf-_H7EcEW3nP5fi7qavJk_K7ltVmaXtN7UZMZGTC1LU"
        })
        console.log(token, "tokefirebase")
        localStorage.setItem("tokefirebase",token)       


        let requestBody = {
            "token":token          
        };
          await ApiConnector.sendPushNotifiPostRequest(
            ApiEndpoints.PUSH_NOTIFICATION_ADD,
            JSON.stringify(requestBody),
          );
        }
    } catch(error){
        console.log("Error occured during token generation",error)
    }

   
}

