import React, { useEffect, useState } from "react";
import "./sidebar.css";
import { Link } from "react-router-dom";
import CookieUtil from "../../util/cookieUtil";
import AppPaths from "../../lib/appPaths";
import ApiConnector from "../../api/apiConnector";
import ApiEndpoints from "../../api/apiEndpoints";
import CommonUtil from "../../util/commonUtil";
import Constants from "../../lib/constants";
import Modal from "../modal/modal";
import ApiUtils from "../../api/apiUtils";
import HttpMethods from "../../api/httpMethods";
import { saveAs } from "file-saver";

const Sidebar = (props) => { 
  const [chatUsers, setChatUsers] = useState([]); // sidebar users
  const [users, setUsers] = useState([]); // popup users
  const [isShowAddPeopleModal, setIsShowAddPeopleModal] = useState(false);
  const [isShowQRCodeModal,setisShowQRCodeModal] = useState(false)
  const [selectedValue, setSelectedValue] = useState('');
  const [storelist, setStorelist] = useState()
  const [urlforQRcode,seturlforQRcode]=useState('')

  const redirectUserToDefaultChatRoom = (chatUsers) => {
    if (props?.location?.pathname === AppPaths.HOME) {
        props.setCurrentChattingMember(chatUsers[0]);
        localStorage.setItem("currentuser",JSON.stringify(chatUsers[0]))
        props.history.push("/c/" + chatUsers[0]?.roomId);
    } else {
        const activeChatId = CommonUtil.getActiveChatId(props.match);
        const chatUser = chatUsers.find((user) => user.roomId === activeChatId);
        props.setCurrentChattingMember(chatUser);
    }
  };

  const fetchChatUser = async () => {
    const url = ApiEndpoints.USER_CHAT_URL.replace(
      Constants.USER_ID_PLACE_HOLDER,
      CommonUtil.getUserId()
    );
    const chatUsers = await ApiConnector.sendGetRequest(url);
    console.log(chatUsers, "chatuser");
    const formatedChatUser = CommonUtil.getFormatedChatUser(
      chatUsers,
      props.onlineUserList,
     
    );
    setChatUsers(formatedChatUser);
    redirectUserToDefaultChatRoom(formatedChatUser);
    console.log(props, "online userlist")
  };

  const fetchChatUserInSearch = async (searchQuery = "") => {
    const url = ApiEndpoints.USER_CHAT_URL.replace(
      Constants.USER_ID_PLACE_HOLDER,
      CommonUtil.getUserId()
    );

    // Append search query to the URL if provided
    const searchUrl = searchQuery ? `${url}?search_key=${encodeURIComponent(searchQuery)}` : url;

    const chatUsers = await ApiConnector.sendGetRequest(searchUrl);
    const formatedChatUser = CommonUtil.getFormatedChatUser(
      chatUsers,
      props.onlineUserList
    );

    setChatUsers(formatedChatUser);
  };

  const handleSearchChange = (event) => {
    const searchQuery = event.target.value;
    fetchChatUserInSearch(searchQuery);
  };

  const UrlforQR = (event) => {
    const urlforQRcode=event.target.value
    seturlforQRcode(urlforQRcode)
    console.log(urlforQRcode)
  }

  const qrcodegeneratingcompleteefunction = async () => {
    const url = "https://image-process-uat-ai.sidrabusiness.com/generate_qr";
    const qrcode = await fetchQRcode(url)
    const qrcodedownloadUrl=URL.createObjectURL(qrcode)
    saveAs(qrcodedownloadUrl)
  }
 

  const fetchQRcode = async (url) => {    
    let options = {
        method: HttpMethods.POST,
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "url": urlforQRcode
        })
    };
    try {
        const response = await fetch(url, options);
        const status = await ApiUtils.statusHandler(response);        
        const data = await status.blob()
        console.log(data,"success")
        return data;
    } catch (error) {
        console.log(error,"error");
    }
};



  const getStoreCat = async () => {
      const url = ApiEndpoints.SORT_STORE
      const storeslist = await ApiConnector.sendGetRequest(url)
      if (storeslist) {        
        setStorelist(storeslist?.data?.results)
        console.log(storelist, "storeslist") 
      }          
  }

  useEffect(() => {
    fetchChatUser(); 
    getStoreCat();  
  }, []); // Added dependency array to prevent infinite loop

  const getConnectedUserIds = () => {
    let connectedUsers = "";
    for (let chatUser of chatUsers) {
      connectedUsers += chatUser.id + ",";
    }
    return connectedUsers.slice(0, -1);
  };

  const fetchUsers = async () => {
    const url = ApiEndpoints.USER_URL + "?exclude=" + getConnectedUserIds();
    const users = await ApiConnector.sendGetRequest(url);
    setUsers(users.results);
  };

  const addPeopleClickHandler = async () => {
    await fetchUsers();
    setIsShowAddPeopleModal(true);
  };



  const addMemberClickHandler = async (memberId) => {
    const userId = CommonUtil.getUserId();
    let requestBody = {
      members: [memberId, userId],
      type: "DM",
    };
    await ApiConnector.sendPostRequest(
      ApiEndpoints.CHAT_URL,
      JSON.stringify(requestBody),
      true,
      false
    );
    fetchChatUser();
    setIsShowAddPeopleModal(false);
  };

  const getActiveChatClass = (roomId) => {
    let activeChatId = CommonUtil.getActiveChatId(props.match);
    console.log(activeChatId,"activechatid")
    return roomId === activeChatId ? " active-chat" : "";
  };

  const logoutClickHandler = async() => {
    CookieUtil.deleteCookie(Constants.ACCESS_PROPERTY);
    CookieUtil.deleteCookie(Constants.REFRESH_PROPERTY);  
    const firebasetokenobj = localStorage.getItem("tokefirebase")
    const firebasetoken=JSON.stringify(firebasetokenobj)  
    console.log(firebasetoken)
    let requestBody = {
      "token":firebasetoken          
              };
        await ApiConnector.sendPushNotifiPostRequest(
          ApiEndpoints.PUSH_NOTIFICATION_REMOVE,
          requestBody,
        );
    window.location.href = AppPaths.LOGIN;
  };

  const getChatListWithOnlineUser = () => {
    let updatedChatList = chatUsers.map((user) => {
      if (props.onlineUserList.includes(user.id)) {
        user.isOnline = true;
      } else {
        user.isOnline = false;
      }
      return user;
    });
    return updatedChatList;
  };
  
  const eachUserclick = (chatUser) => {
    props.setCurrentChattingMember(chatUser)
    localStorage.setItem("currentuser",JSON.stringify(chatUser))
  }

  // qrcode hgenerator
  const qrcodeGenerator = ()=>{
    setisShowQRCodeModal(true)
  }

  // for the store by filter option
  const handleSelectionChange = async (event) => {
    const value = event.target.value;
    setSelectedValue(JSON.stringify(value));
    console.log('Selected value:', value);
    const url = `${ApiEndpoints.USER_CHAT_URL.replace(
      Constants.USER_ID_PLACE_HOLDER, 
      CommonUtil.getUserId()
    )}?channel_name=${encodeURIComponent(value)}`;
    const filteredchatusers = await ApiConnector.sendGetRequest(url);
    console.log(filteredchatusers, "chatuserfiltered");
    const formated = CommonUtil.getFormatedChatUser(
      filteredchatusers,
      props.onlineUserList
    );
    setChatUsers([])
    setChatUsers(formated);
    console.log(chatUsers, "Filteredchatusers")
    redirectUserToDefaultChatRoom(formated);
    
  };



  
  return (
    <div className="col-12 p-0 col-sm-5 col-md-4 col-lg-4 col-xl-4 border-right">
      <button 
        onClick={logoutClickHandler}
        className="btn btn-outline-danger btn-block mt-1 logoutbtn"
      >
        Log Out
      </button>
      
      <div className="py-2 px-4 navbar">       
        <div className="d-flex align-items-center py-1 justify-content-between w-100">  
            <div className="cursor" onClick={qrcodeGenerator}>
              <button>QR</button>
              
            </div>        
              <select id="fruits" onChange={handleSelectionChange}>
                <option value="">All</option>
                {storelist?.map((store,index) => (
                  <option value={store.name_key}>{store.name}</option>
                ))}
              </select>         
        </div>       
      </div>
      <div className="m-2">
        <input
          type="text"
          placeholder="Find users..."
          onChange={handleSearchChange}
          className="form-control my-1"
        />
      </div>
        

      <div className="user-list-container pt-3">
        {getChatListWithOnlineUser()?.length > 0 ? (
          getChatListWithOnlineUser()?.map((chatUser) => {
            return (
              <Link
                onClick={() =>eachUserclick(chatUser)}
                to={`/c/${chatUser.roomId}`}
                className={
                  "pl-1 list-group-item list-group-item-action border-0" +
                  getActiveChatClass(chatUser.roomId)
                }
                key={chatUser.id} // Add a unique key prop
              >
                <div className="d-flex align-items-start">
                  <img
                    src={chatUser.image}
                    className="rounded-circle mr-1"
                    alt={chatUser.name}
                    width="40"
                    height="40"
                  />
                  <div className="flex-grow-1 ml-3 d-flex flex-column">
                    <div>
                      {chatUser.name}                      
                    </div>                  
                    <div className="small whatsappclass">
                    <i class="bi bi-whatsapp" style={{color:"green"}}></i> {chatUser.channel_name}
                    </div>
                  </div>
                </div>
              </Link>
            );
          })
        ) : (
          <div>No users found</div>
        )}
      </div>
      {/* <div className="d-none d-md-block">
        <button
          onClick={addPeopleClickHandler}
          className="btn btn-outline-warning btn-block my-1 mt-4"
        >
          Add People
        </button>
      </div> */}      
      <Modal
        modalCloseHandler={() => setIsShowAddPeopleModal(false)}
        show={isShowAddPeopleModal}
      >
        {users.length > 0 ? (
          users?.map((user) => (
            <div
              key={user.id}
              className="d-flex align-items-start pt-1 pb-1 d-flex align-items-center"
            >
              <img
                src={user.image}
                className="rounded-circle mr-1"
                alt={user.first_name + " " + user.last_name}
                width="40"
                height="40"
              />
              <div className="flex-grow-1 ml-2 mr-5">
                {user.first_name + " " + user.last_name}
              </div>
              <button
                onClick={() => addMemberClickHandler(user.id)}
                className="btn btn-sm btn-success"
              >
                Add
              </button>
            </div>
          ))
        ) : (
          <h3>No More User Found</h3>
        )}
      </Modal>



      <Modal
        modalCloseHandler={() => setisShowQRCodeModal(false)}
        show={isShowQRCodeModal}
      >
      
      <div className="qr">
        <input className="w-100 p-2" type="text" onChange={UrlforQR} placeholder="Enter URL"/>
        <button onClick={qrcodegeneratingcompleteefunction} className="mt-3">Generate</button>
      </div>
    
      </Modal>
    </div>
  );
};

export default Sidebar;
