const ApiEndpoints = {
  SIGN_UP_URL: "api/v1/signup",
  LOGIN_URL: "api/v1/login",
  USER_URL: "api/v1/users",
  CHAT_URL: "api/v1/chats",
  USER_CHAT_URL: "api/v1/users/<userId>/chats",
  CHAT_MESSAGE_URL: "api/v1/chats/<chatId>/messages",
  IMG_SEND_URL:"file-upload",
  TEMP_DATA:"api/v1/message/templates?channel=",
  TEMP_SEND:"api/v1/send/message/templates",
  SORT_STORE: "api/integration/v1/sidrabazar/account-list",
  LANG_API: "language/translate/v2?key=",
  PUSH_NOTIFICATION_ADD:"api/v1/addtoken/+918075973413/",
  PUSH_NOTIFICATION_REMOVE:"api/v1/removetoken/+918075973413/"

};

export default ApiEndpoints;
