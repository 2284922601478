import React, { useEffect, useRef, useState } from "react";
import ApiConnector from "../../api/apiConnector";
import ApiEndpoints from "../../api/apiEndpoints";
import ServerUrl from "../../api/serverUrl";
import Constants from "../../lib/constants";
import SocketActions from "../../lib/socketActions";
import CommonUtil from "../../util/commonUtil";
import "./chatBodyStyle.css";
import Modal from "../modal/modal";
import axios from 'axios';
import template  from "../../assets/template.jpg";


let socket = new WebSocket(
  ServerUrl.WS_BASE_URL + `ws/users/${CommonUtil.getUserId()}/chat/`
  // ServerUrl.WS_BASE_URL + `ws/users/${currentChattingMember?.id}/chat/`
);
let typingTimer = 0;
let isTypingSignalSent = false;

const ChatBody = ({ match, currentChattingMember, setOnlineUserList }) => {
  const [inputMessage, setInputMessage] = useState("");
  const [change, setChange] = useState("");
  const [messages, setMessages] = useState();
  const [messagesmain, setMessagesmain] = useState([]);
  const [typing, setTyping] = useState(false);
  const [paginationcheck, setPaginationcheck] = useState(false);
  const [show, setShow] = useState(false);
  const [recording, setRecording] = useState(false);
  const mediaRecorderRef = useRef(null)
  const audioChunksRef = useRef([])
  const [audioURL, setAudioURL] = useState('');
  const [tempData,setTempData] = useState([])
  const [ showeachtemp, setShoweachtemp] = useState(false)
  const [tempVaribaleData, settempVaribaleData] = useState({
    template_id: '',
    body_data: {},
    user_id: '',
    chatroom_id: ''
  })
  const [tempvaribale, setTempvaribale] = useState()
  const [isInputactive, setIsInputactive] = useState(false)
  const [paginationNextAPI, setPaginationNextAPI] = useState('')
  const fileInputRef = useRef(null);
  const [langpopup, setLangpopup] = useState(false)
  const previousDateRef = useRef('');
  const scrollRef = useRef(null)

 
  const fetchChatMessage1 = async () => {
    const currentChatId = CommonUtil.getActiveChatId(match);
    // console.log(currentChatId,"chatid")
    if (currentChatId) {     
      const url =
        ApiEndpoints.CHAT_MESSAGE_URL.replace(
          Constants.CHAT_ID_PLACE_HOLDER,
          currentChatId
        ) + "?limit=20&offset=0";
      let chatMessages = await ApiConnector.sendGetRequest(url);
      setMessages(chatMessages?.results);
      console.log(chatMessages?.next?.slice(26),"messagesssss")     
      setPaginationNextAPI(chatMessages?.next?.slice(26)) 
      // Scroll to the bottom whenever new data is added
        if (scrollRef.current) {
          scrollRef.current?.scrollIntoView()
          // scrollRef.current.children[0].scrollTop=scrollRef.current.children[0].scrollHeight
          // console.log("scrollrefffff", scrollRef.current.scrollHeight)
          // console.log('BoundingClientRect:', scrollRef.current.children[0].scrollHeight);
          
        }    
    }
  };
   
  const fetchChatMessage = async () => {
    const currentChatId = CommonUtil.getActiveChatId(match);
    // console.log(currentChatId,"chatid")
    if (messagesmain.next!==null) {
      const url =paginationNextAPI
      let chatMessages = await ApiConnector.sendGetRequest(url);
      // console.log(chatMessages,"messagesssss")   
      // console.log(messages, "messagespagination")
      if (chatMessages?.results) {
        const combinedmessages = [...messages, ...chatMessages?.results]
        setMessages(combinedmessages)
        setPaginationNextAPI(chatMessages?.next?.slice(26) )
      }
      // scrollRef.current?.scrollIntoView()
      
   
    }
    
  };

  useEffect(() => {
    fetchChatMessage();
  }, [paginationcheck]);

  useEffect(() => {    
    fetchChatMessage1();
  }, [CommonUtil.getActiveChatId(match)]);

  const handlescrollEvent = (e) => {
      e.preventDefault()
      console.log(e.target)
      const { scrollHeight, scrollTop} = e.target;
      console.log(scrollHeight, "scrollHeight");
      console.log(scrollTop, "scrollTop");
      console.log(window.innerHeight,"innerheight")
      // console.log('BoundingClientRect:', e.target.getBoundingClientRect());

      if (window.innerHeight+(-scrollTop)>=scrollHeight) {
        setPaginationcheck(true)    
        
      }
      else{
        setPaginationcheck(false)
        
      }
    };    


  const getChatMessageClassName = (username) => {
    return username === "Console Admin"
      ? "chat-message-right pb-2"
      : "chat-message-left pb-2";
  };
  const getChatMessageBgColorClassName = (username) =>{
        return username === "Console Admin"
        ? "chat-message-right-bg"
        : "chat-message-left-bg";
    };
  

  socket.onmessage = (event) => {
    const data = JSON.parse(event.data);
    const chatId = CommonUtil.getActiveChatId(match);
    const userId = currentChattingMember?.id;
    if (chatId === data.roomId) {
      if (data.action === SocketActions.MESSAGE) {
        data["userImage"] = ServerUrl.BASE_URL.slice(0, -1) + data.userImage;
        setMessages((prevState) => {
          let messagesState = JSON.parse(JSON.stringify(prevState));
          messagesState.results.unshift(data);
          return messagesState;
        });
        setTyping(false);
      } else if (data.action === SocketActions.TYPING && data.user !== userId) {
        setTyping(data.typing);
      }
    }
    if (data.action === SocketActions.ONLINE_USER) {
      setOnlineUserList(data.userList);
    }
  };

  const messageSubmitHandler = (event) => {
    event.preventDefault();
    if (inputMessage) {
      socket.send(
        JSON.stringify({
          action: SocketActions.MESSAGE,
          message: inputMessage,
          user: currentChattingMember.id.toString(),
          roomId: CommonUtil.getActiveChatId(match),
        })
      );
    }
    setInputMessage("");
    setIsInputactive(false)
    setChange(Date.now());
    fetchChatMessage1()
  };

  const sendTypingSignal = (typing) => {
    // socket.send(
    //   JSON.stringify({
    //     action: SocketActions.TYPING,
    //     typing: typing,
    //     user: currentChattingMember.id.toString(),
    //     roomId: CommonUtil.getActiveChatId(match),
    //   })
    // );
  };

  const chatMessageTypingHandler = (event) => {
    if (event.keyCode !== Constants.ENTER_KEY_CODE) {
      if (!isTypingSignalSent) {
        sendTypingSignal(true);
        isTypingSignalSent = true;
      }
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        sendTypingSignal(false);
        isTypingSignalSent = false;
      }, 3000);
    } else {
      clearTimeout(typingTimer);
      isTypingSignalSent = false;
    }
  };

   // Handle file selection
   const handleFileChange = async (event) => {
    event.preventDefault();
    console.log(event)
    const selectFile=event.target.files[0]
    console.log(selectFile)
    if (selectFile) {
      const formData = new FormData();
      formData.append('upload', selectFile);
      console.log(formData,"formadataaa")
      const response = await ApiConnector.sendFileUpload(
        ApiEndpoints.IMG_SEND_URL, // Ensure this is the correct API endpoint
        formData,
        false,
        true,
      );      
      if (response.status==="success") {
        socket.send(
          JSON.stringify({
            action: SocketActions.MESSAGE,
            message: response.data.upload,
            user: currentChattingMember.id.toString(),
            roomId: CommonUtil.getActiveChatId(match),
            message_type:"image"
          })
        );
      }
      setChange(Date.now());
      console.log(response.data.upload,"response")
      fetchChatMessage1()
      }
    
    
  };
  // Audio sending
  const stopRecording = async ()=>{
    mediaRecorderRef.current.stop();
    setRecording(false);
   
    
  }
  const startRecording = async () =>{
      const stream = await navigator.mediaDevices.getUserMedia({audio:true})
      const media = new MediaRecorder(stream);
      mediaRecorderRef.current=media
      console.log(mediaRecorderRef)
      media.start();
      setRecording(true)

      media.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };
      console.log(audioChunksRef)

      media.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/mp4'});
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioURL(audioUrl);
        audioChunksRef.current = [];
        console.log(audioURL, "AudioURL")
        console.log(audioBlob,"audioBlob")
  
        // Send the audioBlob to the server
        uploadAudio(audioBlob);
      };

      const uploadAudio = async (audioBlob)=>{
        const formData = new FormData();
        formData.append('upload', audioBlob, 'recording.mp4');

        console.log(formData,"formadataaa")
        const response = await ApiConnector.sendFileUpload(
          ApiEndpoints.IMG_SEND_URL, // Ensure this is the correct API endpoint
          formData,
          false,
          true,
        );  
        if (response.status==="success") {
          socket.send(
            JSON.stringify({
              action: SocketActions.MESSAGE,
              message: response.data.upload,
              user: currentChattingMember.id.toString(),
              roomId: CommonUtil.getActiveChatId(match),
              message_type:"audio"
            })
          );
        }
        setChange(Date.now());
        console.log(response.data.upload,"response")
        fetchChatMessage1()  
      }


      
  }

  const addtemplate = async () => {
    await fetchTemplateData()
    setShow(true);
    setShoweachtemp(false)
    
  };

  const fetchTemplateData = async () => {
    const channelnameObj = localStorage.getItem("currentuser")
    const channelname = JSON.parse(channelnameObj)
    console.log(channelname)
    const url = ApiEndpoints.TEMP_DATA.concat(channelname.channel_name.toLowerCase())
    console.log(url, "url")
    const tempDatalist = await ApiConnector.sendGetRequest(url);    
    setTempData(tempDatalist.data.results);
  };

  const eachtemp=(variables,tempID)=>{
    const userDataobj = localStorage.getItem("currentuser")
    const userData = JSON.parse(userDataobj)
    console.log(variables, "variables")
    // console.log(tempID,"tempID")
    // console.log(userData, "userdata")
        setTempvaribale(variables)
        setShow(false)
        setShoweachtemp(true)    
        console.log(tempvaribale, "tempvaribale")
        settempVaribaleData({
          "template_id":tempID,
          "body_data":{},
          "user_id":userData.id,
          "chatroom_id":userData.roomId
        })

  }

  const backtempData=()=>{
    setShoweachtemp(false) 
    setShow(true)
  }

  const sendtemplate = async (event) => {
    event.preventDefault()
    console.log(tempVaribaleData , "tempvaribale data")    
    const url =
        ApiEndpoints.TEMP_SEND
      let tempsending = await ApiConnector.sendPostRequest(url,JSON.stringify(tempVaribaleData),
        false,
        false,);

        console.log(tempsending, "response")
        setShoweachtemp(false) 
        setShow(false)
  }

  const handleInputChange = (event, key) => {
    console.log(event.target.value)
    settempVaribaleData((prevData) => ({
      ...prevData,
      body_data: {
        ...prevData.body_data,
        [key]: event.target.value
      }
    }));
  };

  const inputvaluechange = (event) => {
      setInputMessage(event.target.value)
      if (event.target.value==='') {
        setIsInputactive(false)
      }
      else{
        setIsInputactive(true)
      }
  }

  const handleButtonClick = () => {
    // Trigger the file input dialog
    fileInputRef.current.click();
  };

  // Here api included aoi call for google translater
  const handleLanguageselection= async (value) => {
    console.log(value)
    if (inputMessage) {
      let key="AIzaSyDJavpenypIG-Kd1sTSUeEk6jyJ4NpsAFA"
      let modal={
        "q": inputMessage, 
        "target":value,     
      }      
      const url =ServerUrl.BASE_SENTENCETRANS_URL+ApiEndpoints.LANG_API+key        

      const response = await axios.post(url, modal, {
            headers: {
              'Content-Type': 'application/json',
            },            
          });
          console.log(response.data.data?.translations[0]?.translatedText, "textresponse")
          setInputMessage(response.data.data?.translations[0]?.translatedText)
          setLangpopup(false)
      }   
      setLangpopup(false)
  }

  const showdate = (date) => {
    const previousDate = previousDateRef.current;
    if (date === previousDate) {
        return false;
    } else {
        previousDateRef.current = date;
        return true;
    }
};
 



  return (
    <div className="col-12 col-sm-7 col-md-8 col-lg-8 col-xl-8 pl-0 pr-0" id="body">
      <div className="py-2 px-4 chatnavbar">
        <div className="d-flex align-items-center py-1">
          <div className="position-relative">
            <img
              src={currentChattingMember?.image}
              className="rounded-circle mr-1"
              alt="User"
              width="40"
              height="40"
            />
          </div>
          <div className="flex-grow-1 pl-3">
            <strong>{currentChattingMember?.name}</strong>
          </div>
        </div>
      </div>
      <div className="position-relative">
        <div
          
          onScroll={handlescrollEvent}
          id="chat-message-container"
          className="chat-messages pl-4 pt-4 pr-4 pb-1 d-flex flex-column-reverse"
        >
          {typing && (
            <div className="chat-message-left chat-bubble mb-1">
              <div className="typing">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
            </div>
          )}
           
           <div ref={scrollRef} ></div>  
          {messages?.map((message, index) => (
            <div  className="d-flex flex-column">
               {showdate(message.messageDate) && 
               <div className="datediv text-muted text-nowrap">
                    {message.messageDate}
                </div>
                }   
              <div className={getChatMessageClassName(message.userName)}>
                <div key={index} className={getChatMessageBgColorClassName(message.userName)+" py-2 chattemp flex-shrink-1 ml-1 rounded px-3 mr-3 d-flex flex-row"}>                
                                
                  <div className="eachmessage">                
                    {message.message_type === "text" && <span>{message.message}</span>}
                    {message.message_type === "image" && (
                      <img width="150" src={message.message} alt="Image"/>
                    )}
                    {message.message_type ==="audio" && (
                      <audio controls>
                        <source src={message.message} type="audio/ogg" />
                        Your browser does not support the audio element.
                      </audio>                  
                    )}
                    {message.message_type === "template" &&                         
                          <div>   
                            <span>Template Send</span>   
                            <br />                    
                            <img src={template} width={"100px"} height={"100px"} alt="" />  
                          
                          </div>
                          }
                  </div>                
                  <div className="d-flex flex-column justify-content-end align-items-end ml-2"> 
                        
                      {message.message_status==="failed" &&
                        <i type="button" className="bi bi-exclamation-circle align-self-center" data-bs-toggle="tooltip" data-bs-placement="top" title={message.messageErrors} style={{color:'red'}}></i>
                      }
                      {message.message_status==="send" &&
                        <i type="button" className="bi bi-check align-self-center" data-bs-toggle="tooltip" data-bs-placement="top" title={message.messageErrors} style={{color:'#80808096'}}></i>
                      }
                      {message.message_status==="read" &&
                        <i type="button" className="bi bi-check-all align-self-center" data-bs-toggle="tooltip" data-bs-placement="top" title={message.messageErrors} style={{color:'blue'}}></i>
                      }
                        {message.message_status==="delivered" &&
                        <i type="button" className="bi bi-check-all align-self-center" data-bs-toggle="tooltip" data-bs-placement="top" title={message.messageErrors} style={{color:'#80808096'}}></i>
                      }  
                        <div className="text-muted text-nowrap" style={{fontSize:"9px"}}>
                            {CommonUtil.getTimeFromDate(message.timestamp)}
                        </div>
                    </div>
                </div> 
                      
              </div>
            </div>           
          ))}
             
        </div>
      </div>
      <div className="flex-grow-0 py-3 px-4 border-top d-flex">
        <form onSubmit={messageSubmitHandler} style={{width:'100%'}}>
          <div className="input-group">
          {/* <audio src={audioURL} controls /> */}
            <input
              onChange={(event) => inputvaluechange(event)}
              onKeyUp={chatMessageTypingHandler}
              value={inputMessage}
              id="chat-message-input"
              type="text"
              spellCheck="true"
              className="form-control"
              placeholder="Type your message"
              autoComplete="off"
              style={{ borderRight: 'none' }}       
            />
              <div className="lngtraslater d-flex justify-content-center align-items-center">
                  <i class="bi bi-translate langicon" onClick={()=>setLangpopup((prev)=>!prev)} data-bs-toggle="tooltip" data-bs-placement="top" title="Change sentence language"></i>
                  {langpopup && <div className="langpopup">
                      <ul className="langUl">
                        <li className="langlist" onClick={()=>handleLanguageselection("ml")}>Malayalam</li>
                        <li className="langlist"  onClick={()=>handleLanguageselection("hi")}>Hindi</li>
                        <li className="langlist" onClick={()=>handleLanguageselection("ar")}>Arabic</li>
                      </ul>
                  </div>}
              </div>
            

            
              <i className="bi bi-hdd-stack templates"  onClick={addtemplate} data-bs-toggle="tooltip" data-bs-placement="top" title="Add template"></i>
              <input
                  type="file"
                  accept="image/*"                
                  className="file-input"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  />
                <i className="bi bi-image-alt imageupdate d-flex justify-content-center align-items-center" onClick={handleButtonClick} data-bs-toggle="tooltip" data-bs-placement="top" title="Select Image"></i>
             
           {isInputactive ? (
            <button
              id="chat-message-submit"
              className="btn btn-outline-warning"
            >
              Send        
            </button> 
             ) : (          
            <button
              id=""
              className="btn btn-outline-warning"
              onClick={recording ? stopRecording : startRecording}
              style={{width:"61px"}}
            >  
              {recording?<i className="bi bi-stop-fill"></i>:<i className="bi bi-mic"></i>}             
          </button> 
            )}
          </div>
        </form>
       
       
      </div>


      <Modal
          modalCloseHandler={() => setShow(false)}
          show={show}
        >  
          <div className="head">
              <h5>Whatsapp Templates</h5>
          </div>
          {tempData.length!==0 &&
          <div className="container-fluid row w-100 d-flex flex-column pt-5">
                {tempData.map((data, index) => (
                  <div className="eachtempData mt-3" onClick={()=>eachtemp(data.variables,data.template_id,)}>
                    <h6>{data.name}</h6>
                    <p>{data.message_body}</p>
                  </div>  
                ))}
          </div>}
          {tempData.length===0 && <div className="container-fluid row w-100 d-flex justify-content-center align-items-center h-100">No Templates Found</div>}
      </Modal>

      <Modal
          modalCloseHandler={() => setShoweachtemp(false)}
          show={showeachtemp}
        >  
          <div className="head">
              <h5>Whatsapp Templates</h5>
              <div onClick={backtempData}><i className="bi bi-arrow-left"></i> Back</div>
          </div>          
           {tempvaribale? (<div className="container-fluid row w-100 d-flex flex-column" style={{paddingTop:"90px"}}>    
                <form action="" className="form-group" onSubmit={sendtemplate}>       
                  <ul >
                  {tempvaribale.map((variable, index) => (
                    <><li key={index}>{variable}</li>
                    <input  type="text"
                            required
                            value={tempVaribaleData.body_data[variable] || ''}
                            className="form-control"
                            onChange={(event) => handleInputChange(event, variable)} /></>
                    ))}
                  </ul>
                  <button type="submit" className="btn btn-primary submitbtn">Submit</button>
                </form> 
           </div>):(
            <p>dsd</p>
           )}
           
      </Modal>

    </div>
  );
};

export default ChatBody;
